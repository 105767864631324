@tailwind base;
@tailwind components;
@tailwind utilities;

:root {

    --white-100:"#fff";
  
    --green-100: '#21ce99';
    --green-300: '#00B37E';
    --green-500: '#00875F';
    --green-700: '#015F43';
    --green-800: '#006400',
  
    --blue-500: '#81D8F7';
    --blue-600: '#00E3F0';
    --blue-900: '#01244A';
    --blue-999: '#01091f';
    --blue-1000: '#040d14';
  
    --orange-500: '#FBA94C';
    --orange-800: '#f45734';
    
    --red-500: '#F75A68';
    
    --gray-50: ' #d0d1d3';
    --gray-100: '#E1E1E6';
    --gray-200: '#C4C4CC';
    --gray-300: '#8D8D99';
    --gray-500: '#323238';
    --gray-600: '#3b4141';
    --gray-700: '#121214';
    --gray-800: '#0a1211';
    --gray-900: '#09090A';
  
  }
  
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

